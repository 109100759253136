import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Container } from "../../Section"
import styled from "@emotion/styled"
import { Text } from "@theme-ui/components"
import { css } from "@emotion/core"

const ContentText = ({ content }) => {
  const { copy, paddingTop, paddingBottom } = content

  const renderOptions = {
    renderText: text =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  }

  return (
    <Container outset={true}>
      <Row>
        <Cols>
          <TextWrapper paddingTop={paddingTop} paddingBottom={paddingBottom}>
            <Text as="div" variant="textBase">
              {documentToReactComponents(copy.json, renderOptions)}
            </Text>
          </TextWrapper>
        </Cols>
      </Row>
    </Container>
  )
}

export default ContentText

const Row = styled.div`
  width: 100%;
  margin: 0 auto;
`

const Cols = styled.div`
  margin: 0 auto;
  max-width: 1300px;
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const TextWrapper = styled.div`
  width: 100%;
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    width: ${(6 / 12) * 100}%;
  }

  ${({ paddingTop, theme }) =>
    paddingTop
      ? css`
          padding-top: 3rem;
          @media (min-width: ${theme.breakpoints[1]}) {
            padding-top: 4.5rem;
          }
        `
      : ``};
  ${({ paddingBottom, theme }) =>
    paddingBottom
      ? css`
          padding-bottom: 3rem;
          @media (min-width: ${theme.breakpoints[1]}) {
            padding-bottom: 4.5rem;
          }
        `
      : ``};

  a {
    color: inherit;
    position: relative;
    transition: top 0.2s ease;
    top: 0px;
    &:hover {
      opacity: 0.9;
      top: -0.05em;
    }
  }
  ul,
  ol {
    margin: 0;
    padding: 0;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 1em;
    li {
      margin-top: 0em;
      margin-bottom: 0.5em;
      p:last-child {
        margin-bottom: 0em;
      }
    }
  }

  p {
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`
