import React from "react"
import Img from "gatsby-image"
import { Container } from "../../Section"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const ContentImage = ({ content }) => {
  const { image, paddingTop, paddingBottom } = content
  return (
    <Container outset={true}>
      <Row>
        <Cols>
          <ImageCol paddingTop={paddingTop} paddingBottom={paddingBottom}>
            <Img fluid={image.fluid} />
          </ImageCol>
        </Cols>
      </Row>
    </Container>
  )
}

export default ContentImage

const Row = styled.div`
  width: 100%;
  margin: 0 auto;
`

const Cols = styled.div`
  margin: 0 auto;
  max-width: 1300px;
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ImageCol = styled.div`
  width: 100%;
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    width: ${(10 / 12) * 100}%;
  }

  img {
    width: 100%;
    height: 100%;
  }

  ${({ paddingTop, theme }) =>
    paddingTop
      ? css`
          padding-top: 3rem;
          @media (min-width: ${theme.breakpoints[1]}) {
            padding-top: 4.5rem;
          }
        `
      : ``};
  ${({ paddingBottom, theme }) =>
    paddingBottom
      ? css`
          padding-bottom: 3rem;
          @media (min-width: ${theme.breakpoints[1]}) {
            padding-bottom: 4.5rem;
          }
        `
      : ``};
`
