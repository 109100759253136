import React, { useContext, useEffect, useRef } from "react"
import { useLocation } from "@reach/router"
import styled from "@emotion/styled"
import ReactPlayer from "react-player"
import { Heading } from "theme-ui"
import LogoMark from "../Svg/LogoMark"
import BackLink from "./BackLink"
import { colors } from "../../styles/settings"
import { TagButton } from "../TagLink"
import { VideoContext } from "../../hooks/useFeatureVideo"
import Hls from "hls.js"

const Hero = ({
  title,
  media,
  headerVideo,
  featureVideoText,
  featureVideoUrl,
  isOverlay,
  overlayOpacity,
  section,
  featureVideoMux,
}) => {
  const location = useLocation()
  const [videoUrl, setVideoUrl] = useContext(VideoContext)

  let images
  let image

  if (headerVideo?.playbackId) {
    image = {
      file: {
        url: `https://image.mux.com/${headerVideo.playbackId}/thumbnail.png?time=0`,
      },
    }
  } else {
    if (media) {
      images = media.filter(item =>
        item.file.contentType.match(/image\/(jpeg|jpg)/)
      )
      image = images.length > 0 ? images[0] : null
    }
  }

  // const videos = media
  //   .filter(item => item.file.contentType.match(/video\/(mp4|ogg|webm)/))
  //   .map(item => {
  //     return {
  //       src: item.file.url,
  //       type: item.file.contentType,
  //     }
  //   })

  const videoLink =
    featureVideoText &&
    featureVideoText !== "" &&
    featureVideoUrl &&
    featureVideoUrl !== "" ? (
      <VideoLink
        text={featureVideoText}
        url={`https://stream.mux.com/${featureVideoMux?.playbackId}.m3u8`}
        setVideoUrl={setVideoUrl}
      />
    ) : null

  const videoRef = useRef()
  const { playbackId } = headerVideo || {}
  const streamUrl = `https://stream.mux.com/${playbackId}.m3u8`
  useEffect(() => {
    let hls
    const src = streamUrl
    if (videoRef.current && streamUrl !== "") {
      const video = videoRef.current
      if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = src
      } else if (Hls.isSupported()) {
        hls = new Hls()
        hls.loadSource(src)
        hls.attachMedia(video)
      } else {
        console.error("This is a legacy browser that doesn't support MSE")
      }
    }
    return () => {
      if (hls) {
        hls.destroy()
      }
    }
  }, [videoRef, streamUrl])

  return (
    <Wrap>
      <Bg image={image && image.file.url}>
        <HeroVideo
          streamUrl={streamUrl}
          playbackId={playbackId}
          videoRef={videoRef}
        />
        <Content>
          {location && location.pathname === "/" ? (
            <LogoEl>
              <LogoMark color={colors.granola} />
            </LogoEl>
          ) : (
            <Heading
              as="h1"
              variant="text3Xl"
              sx={{
                width: "100%",
                maxWidth: 936,
                color: "white",
                margin: "0 auto",
                marginBottom: ".3em",
              }}
            >
              {title}
            </Heading>
          )}
          {videoLink}
        </Content>
        {isOverlay && isOverlay === "Yes" ? (
          <BgOverlay
            opacity={
              overlayOpacity && overlayOpacity <= 100 ? overlayOpacity : 0
            }
          />
        ) : null}
      </Bg>
      <BackLink section={section} variant="alternative" hasHero={true} />
    </Wrap>
  )
}

const VideoLink = ({ text, url, setVideoUrl }) => {
  return (
    <>
      <TagButton onClick={() => setVideoUrl(url)} variant="filled" icon="play">
        {text}
      </TagButton>
    </>
  )
}

export default Hero

const HeroVideo = ({ videoRef, streamUrl, playbackId }) => {
  return (
    <video
      className="video-player"
      src={streamUrl}
      ref={videoRef}
      width="100%"
      height="100%"
      playsInline
      autoPlay={true}
      muted
      loop
    />
  )
}

const Wrap = styled.header`
  height: 90vh;
  position: relative;
  z-index: 1;
`

const Bg = styled.div`
  position: relative;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const BgOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: ${props => `rgba(17, 31, 48, ${props.opacity / 100})`};
  z-index: 2;
`

const Content = styled.div`
  position: absolute;
  top: 48%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
  z-index: 3;
`

const LogoEl = styled.div`
  display: block;
  margin: 0 auto;
  margin-bottom: 2em;
`
